import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { useLottie, useLottieInteractivity } from 'lottie-react';
import { isArray } from '@apollo/client/utilities';

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    display: grid;
    grid-template-columns: auto;
  }
`;

export const LottieComponent = ({ animationData, mode, actions, loop = true, autoplay = true }: LottieProps) => {
  const options = {
    loop,
    autoplay,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    ...(mode && { mode }),
    ...(isArray(actions) && actions.length > 0 && { actions }),
  };

  const { View } = useLottie(options);

  return <Container>{View}</Container>;
};

export const LottieComponentMemoized = memo(LottieComponent);

interface LottieProps extends React.HTMLAttributes<HTMLDivElement> {
  animationData: any;
  mode?: 'scroll' | 'cursor';
  actions?: any[];
  loop?: boolean;
  autoplay?: boolean;
}

// https://lottiefiles.com/interactivity
